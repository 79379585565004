<template>
  <div class="block w-full">
    <div class="py-4">
        <div class="flex">
            <div class="sm:flex flex-wrap">         
                <item-text title="Rindas nr." :text="error.row" />
                <item-text title="Kļūdas paziņojums" :text="error.message" />
            </div>

            <div class="md:flex-shrink-0 flex items-center flex-wrap ml-auto">
                <Button :icon="showErrorValues ? 'chevron-up' : 'chevron-down'" tooltip="Atvērt vērtības" @click="showErrorValues = !showErrorValues" />
            </div>
        </div>
    </div>

    <template v-if="showErrorValues">
        <div class="bg-gray-100 dark:bg-gray-800 px-6 py-3 rounded-lg">
            <code class="language-js block whitespace-pre truncate dark:text-gray-400">{{error.values}}</code>
        </div>
    </template>

  </div>
</template>
<script>
import ItemText from "@/components/Components/ItemText"

export default {
  name: "SingleStockCheckError",
  components: {
    ItemText,
  },
  props: {
    error: {
      type: Object,
      required: true,
    }
  },
  data() {
      return {
          showErrorValues: false,
      }
  }
}
</script>

<style>

</style>