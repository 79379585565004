<template>
    <item-card mb="5" :content="showFullList">
        <template v-slot:title>
            <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300 mt-2">
                Kļūdas - {{errors.length}}
            </h3>
        </template>

        <template v-slot:buttons>
            <template v-if="errors.length > 0">
                <Button :icon="showFullList ? 'chevron-up' : 'chevron-down'" tooltip="Visas kļūdas" @click="showFullList = !showFullList" />
            </template>
        </template>
        
        <template v-slot:content>
            <div class="px-4 w-full">
                <template v-for="(error, index) in allErrors" :key="index">
                    <SingleStockCheckError :error="error" />
                </template>
            </div>
        </template>
    </item-card>
</template>

<script>
import ItemCard from "@/components/Components/ItemCard"
import SingleStockCheckError from "@/components/Stock/StockChecks/StockCheckErrors/SingleStockCheckError"

export default {
    name: "StockCheckErrors",
    components: {
        ItemCard,
        SingleStockCheckError,
    },
    props: ['errors'],
    mounted() {
        this.$store.dispatch('clearEditableCustomerContact')
        this.showFullList ? this.allErrors = [...this.errors] : this.allErrors = [...this.errors].slice(0, 0)
    },
    data() {
        return {
            allErrors: [],
            showFullList: false,
        }
    },
    watch: {
        showFullList() {
            this.showFullList ? this.allErrors = [...this.errors] : this.allErrors = [...this.errors].slice(0, 0)
        },
    },
}
</script>

<style>

</style>